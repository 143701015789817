import React, { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Spinner from 'src/components/spinner/spinner';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { getToken, isCognito, resetSession, userIsGlobalAdmin } from 'src/utils/auth';
import ROUTES from 'src/utils/routes';
import { retrieveUserVenues } from 'src/utils/storage/local-storage';

const isBrowser = typeof window !== 'undefined';

export const usePrivateRoute = () => {
  const c = useApolloClient();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      if (isCognito() && !getClientConfig().isSaaS) {
        const authPingFetch = fetch(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account/Provider`, {
          credentials: 'include',
        });
        const venues = retrieveUserVenues();

        if (!venues?.length && !userIsGlobalAdmin()) {
          resetSession(c, location.pathname);
        } else {
          // This is JSONP but it's simpler just to check the string, we don't need the JSONP
          const authPing = await authPingFetch;
          if (!authPing.ok || (await authPing.text()) === "provider('None')") {
            resetSession(c, location.pathname);
          }
        }
      } else if (!getToken()) {
        resetSession(c, location.pathname);
      }
    })();
  }, [c, location.pathname]);
};

export const PrivateRoute: React.FC<{ path?: string }> = ({ children }) => {
  usePrivateRoute();
  const location = useLocation();
  const { disableModules: dm } = getClientConfig();
  if (!location.pathname.startsWith(ROUTES.LOGIN) && dm && dm.some((m) => location.pathname.startsWith(`/${m}`))) {
    isBrowser && navigate(ROUTES.NOT_FOUND);
    return <Spinner />;
  }
  return <>{children}</>;
};
