import React, { ElementType, ReactNode } from 'react';

import classNames from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './page-header.module.less';

interface Props extends SpacingProps {
  title: ReactNode;
  size?: 'sm' | 'md';
  noPadding?: boolean;
  as?: ElementType;
}

const PageHeader: React.FC<Props> = ({ title, noPadding, spacing, size = 'sm', as: Tag = 'h2' }) => {
  const spacingClasses = useSpacing(spacing);
  return (
    <Tag
      className={classNames(styles.title, spacingClasses, styles[size], {
        [styles.titlePadding]: !noPadding,
        [styles.noMargin]: !spacingClasses,
      })}
    >
      {title}
    </Tag>
  );
};

export default PageHeader;
