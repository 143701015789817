import graphql from 'graphql-tag';

export const LIST_MEMBERSHIPS = graphql`
  query Memberships($filter: MembershipFilterOptionsInput!) {
    memberships(filter: $filter) {
      id: membershipId
      membershipId
      name
      code
      totals {
        count
        revenue
      }
    }
  }
`;
export const GET_MEMBERSHIP = graphql`
  query Membership($membershipId: ID!) {
    membership(membershipId: $membershipId) {
      membershipId
      name
      code
      type
      region
      terms {
        startDate
        endDate
        status
        name
        termId
        code
        pricingDescription
        pricingRules
      }
      description
      benefits
      confirmation
      paymentMethodAvailable
      tax
    }
  }
`;
export const GET_MEMBERSHIP_MEMBERS = graphql`
  query GetMembershipMembers($filter: MemberFilterOptionsInput!, $pagination: PaginationArgs!, $includeTotal: Boolean) {
    findMembers(filter: $filter, pagination: $pagination, includeTotal: $includeTotal) {
      results {
        id: memberId
        startDate
        endDate
        payment {
          date
          total
          price
          amountPaid
          status
        }
        membershipId
        organisationId
        memberId
        termId
        memberStatus
        organisationName
      }
      total
    }
  }
`;
export const GET_MEMBER_BY_ID = graphql`
  query GetMemberById($memberId: ID!) {
    getMemberById(memberId: $memberId) {
      startDate
      endDate
      registeredEmail
      registrationDate
      memberId
      memberStatus
      organisationName
      payment {
        date
        status
        allOrderIds
        orderId
      }
    }
  }
`;
export const GET_MEMBER = graphql`
  query GetMember($organisationId: ID!, $membershipId: ID!, $termId: ID!) {
    getMember(organisationId: $organisationId, membershipId: $membershipId, termId: $termId) {
      startDate
      endDate
      registeredEmail
      registrationDate
      memberId
      memberStatus
      organisationName
      payment {
        date
        status
        allOrderIds
        orderId
        total
        tax
        price
        amountPaid
        method
        referenceNumber
        userEmail
      }
    }
  }
`;
export const GET_TRANSACTIONS_BY_PRODUCT_TYPE = graphql`
  query OrderGetGetTransactionsByProductTypeForOrder(
    $clubSparkOrganisationId: UUID
    $orderId: UUID
    $productTypeId: UUID
  ) {
    orderGetGetTransactionsByProductTypeForOrder(
      clubSparkOrganisationId: $clubSparkOrganisationId
      orderId: $orderId
      productTypeId: $productTypeId
    ) {
      results {
        id
        payDate
        source
        paymentMethod
        status
        amount
        entityType
        resultTypeDescription
      }
    }
  }
`;

export const CREATE_MEMBERSHIP = graphql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(createMembershipInput: $input) {
      membershipId
    }
  }
`;

export const UPDATE_MEMBERSHIP = graphql`
  mutation UpdateMembership($id: ID!, $input: UpdateMembershipInput!) {
    updateMembership(updateMembershipInput: $input, membershipId: $id) {
      membershipId
    }
  }
`;

export const ADD_MEMBERSHIP_TERM = graphql`
  mutation AddMembershipTerm($input: AddMembershipTermInput!, $membershipId: ID!) {
    addMembershipTerm(addMembershipTermInput: $input, membershipId: $membershipId) {
      termId
    }
  }
`;

export const UPDATE_MEMBERSHIP_TERM = graphql`
  mutation UpdateMembershipTerm($membershipId: ID!, $termId: ID!, $input: UpdateMembershipTermInput!) {
    updateMembershipTerm(membershipId: $membershipId, termId: $termId, updateMembershipTermInput: $input) {
      termId
    }
  }
`;

export const MARK_INVOICE_AS_PAID = graphql`
  mutation MarkInvoiceAsPaidForMember($memberId: ID!, $paymentDate: DateTime!) {
    markInvoiceAsPaidForMember(id: $memberId, paymentDate: $paymentDate) {
      memberId
      payment {
        date
        status
      }
    }
  }
`;

export const GET_ORGANISATION_PURCHASED_TERMS = graphql`
  query GetOrganisationPurchasedTerms($membershipId: String!, $organisationId: String!) {
    findMembers(
      filter: { membershipId: $membershipId, organisationId: $organisationId, memberStatus: { nin: [NOTDEFINED] } }
      pagination: { skip: 0, limit: 100 }
    ) {
      results {
        memberId
        termId
      }
    }
  }
`;

export const GET_MAIN_CONTACT_DETAILS = graphql`
  query GetMainContactDetails($organisationId: String!) {
    getOrganisationById(organisationId: $organisationId) {
      mainContact {
        phoneNumber
        email
        standardFamilyName
        standardGivenName
      }
    }
  }
`;

export const GET_MEMBERSHIP_OWNING_ORG = graphql`
  query GetMembershipOwningOrg($organisationId: String!) {
    getOrganisationById(organisationId: $organisationId) {
      organisationName
    }
  }
`;

export const ADD_ORG_EXTENSIONS = graphql`
  mutation addOrgExtensions($clubSparkOrganisationId: String!, $updateInput: [ExtensionInput!]!) {
    upsertOrganisationExtensions(organisationId: $clubSparkOrganisationId, extensions: $updateInput) {
      extensions {
        description
        name
        value
      }
    }
  }
`;

export const GET_ORG_EXTENSIONS = graphql`
  query getOrganisationById($clubSparkOrganisationId: String!) {
    getOrganisationById(organisationId: $clubSparkOrganisationId) {
      organisationId
      organisationName
      extensions {
        name
        value
      }
    }
  }
`;
