import React from 'react';

import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId, useOrgName } from 'src/apollo/local-state';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { Memberships, Memberships_memberships } from 'src/graphql-types/saas/Memberships';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import ROUTES from 'src/utils/routes';

import { LIST_MEMBERSHIPS } from './saas-memberships-queries';

type TableItem = Memberships_memberships & { id: string };
const useQFT = initQueryFilterTableHook<Memberships, TableItem>(LIST_MEMBERSHIPS);

export const SaasMemberships: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useOrgId();
  const orgName = useOrgName();

  const {
    components: { AdminTable, AccessoryBar, props },
  } = useQFT(
    {},
    {
      columns: [
        {
          key: 'name',
          getValue: (membership) => <Link to={membership.membershipId}>{membership.name}</Link>,
          title: t('name'),
        },
        {
          key: 'code',
          getValue: (membership) => membership.code,
          title: t('code'),
        },
        {
          key: 'all time memberships sold',
          getValue: (membership) => membership.totals.count,
          title: t('all time memberships sold'),
        },
        {
          key: 'all time membership revenue',
          getValue: (membership) => {
            return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(
              membership.totals.revenue / 100,
            );
          },
          title: t('all time membership revenue'),
        },
      ],
      accessoryBar: {
        items: [
          {
            type: 'router-link',
            position: 'right',
            props: { to: ROUTES.SAAS_MEMBERSHIPS_ADD, fluid: true, children: t('add membership') },
          },
        ],
      },
    },
    {
      columnSelectorId: 'saas-memberships',
      mapFiltersToQueryOptions: () => {
        return {
          client: meshGatewayClient,
          getTotalItems: (data) => data.memberships.length,
          transformVariables: () => {
            return {
              filter: {
                region: orgId,
              },
            };
          },
          skip: !orgId,
        };
      },
      mapDataToTable: (data) => data?.memberships as TableItem[],
    },
  );

  if (!orgId) return <Spinner />;

  return (
    <PageMaxWidth>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader title={t('memberships')} />
      </Grid>
      <Panel extendedPadding headerEndContent={<AccessoryBar {...props.accessoryBar} />} title={orgName}>
        <AdminTable {...props.adminTable} hideTopPaginationInfo spacing={{ margins: { sm: 'top' } }} />
      </Panel>
    </PageMaxWidth>
  );
};
